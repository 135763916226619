<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة زبون</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col>
            <BTextInputWithValidation
              rules="required"
              label="اسم الزبون"
              name="اسم الزبون"
              v-model="customersDto.name"
              placeholder="أدخل اسم الزبون"
            />
            <label>صورة</label>
            <fileDragDrop
              @inValidFile="unValidThumbImage"
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              :src="customersDto.logoDto.base64"
              style="max-height: 320px; object-fit: fill;"
              class="w-100"
            />
            <small class="text-danger">
              {{
                !this.customersDto.logoDto.base64 ? "صورة الزبون مطلوبة" : ""
              }}
            </small>
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
                variant="outline-primary"
                class="ml-auto mr-1"
                @click="is = false"
                >إلغاء</b-button
              >
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  import { ValidationObserver } from "vee-validate";
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "BootstrapForm",
    components: {
      ValidationObserver,
      BTextInputWithValidation,
      fileDragDrop,
    },
    data: () => ({
      customersDto: {
        name: "",
        logoDto: {
          base64: "",
        },
      },
    }),
    computed: {
      ...mapGetters(["customerDetails"]),
      is: {
        get() {
          return this.$store.getters["isCreateCustomerModal"];
        },
        set(is) {
          this.$store.dispatch("setIsCreateCustomerModal", is);
        },
      },
    },
    methods: {
      ...mapActions(["setNewCustomer"]),
      onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if (success && this.customersDto.logoDto.base64) {
            this.setNewCustomer({
              id: 0,
              name: this.customersDto.name,
              logoDto: {
                id: 0,
                path: null,
                base64: this.customersDto.logoDto.base64,
              },
            });
            this.resetForm();
          }
        });
      },
      resetForm() {
        Object.assign(this.customersDto, {
          name: "",
          logoDto: {
            base64: "",
          },
        }),
          Object.assign(this.customerDetails, {
            id: 0,
            name: "",
            logoDto: {
              id: 0,
              path: "",
              base64: "",
            },
          });
      },
      uploadThumbImage(file) {
          this.customersDto.logoDto.base64 = file[0].base64;
      },
      unValidThumbImage() {
        console.log("un valid Thumb file");
      },
    },
  };
</script>
